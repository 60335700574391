import {forwardRef} from 'react';
import {FieldError} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';

export interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  label: string;
  error?: FieldError;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({label, error, className, id, disabled, ...props}, ref) => {
    return (
      <div className={twMerge('flex flex-col gap-2', className)}>
        <label className="uppercase font-bold" htmlFor={id}>
          {label}
        </label>
        <textarea
          className={twMerge(
            `bg-gray-100 rounded-xl py-4 px-8 text-black font-medium`,
            disabled ? 'opacity-50' : ''
          )}
          disabled={disabled}
          ref={ref}
          rows={6}
          id={id}
          {...props}
        />

        {error ? (
          <p className="text-red-500 font-medium">{error.message}</p>
        ) : null}
      </div>
    );
  }
);
