import {forwardRef} from 'react';
import {FieldError} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  error?: FieldError;
  labelProps?: React.HTMLProps<HTMLLabelElement>;
  renderInputEnd?: () => React.ReactNode;
  hint?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      className,
      id,
      disabled,
      labelProps,
      renderInputEnd,
      hint,
      ...props
    },
    ref
  ) => {
    return (
      <div className={twMerge('flex flex-col gap-2', className)}>
        <label className="uppercase font-bold" htmlFor={id} {...labelProps}>
          {label}
        </label>

        {hint ? <p className="text-sm text-gray-600">{hint}</p> : null}

        <div className="bg-gray-100 rounded-xl flex relative">
          <input
            id={id}
            ref={ref}
            disabled={disabled}
            className={twMerge(
              'rounded-xl flex-1 py-4 px-8 bg-transparent text-black font-medium',
              disabled && 'opacity-50 cursor-not-allowed'
            )}
            {...props}
          />

          {renderInputEnd ? renderInputEnd() : null}
        </div>

        {error ? (
          <p className="text-red-500 font-medium">{error.message}</p>
        ) : null}
      </div>
    );
  }
);
